@import '../../assets/styles/colors.scss';

.k-drawer {
  &-item {
    align-items: center;
    padding: 0;
    position: relative;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-left: 46px;
    box-sizing: border-box;
    overflow: hidden;
  }

  &-wrapper,
  &-items {
    height: 100%;
  }

  &-wrapper {
    border-right: 1px solid #ccc;
    overflow: hidden;
  }

  &-items {
    min-width: 265px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.k-drawer-mini {
  .k-drawer-items {
    min-width: 60px;
    transition-delay: .3s;
  }
}

.main-menu-item[aria-haspopup="true"] .k-menu-link:before {
  position: absolute;
  content: '';
  top: 0;
  left: 4px;
  bottom: 0;
  height: 0px;
  width: 0px;
  margin: auto;
  border: 4px solid;
  border-color: transparent;
  border-left-color: inherit;
  border-left-width: 6px;
}

.main-menu-item .k-menu-expand-arrow {
  display: none;
}

.k-drawer-expanded {
  .k-drawer-items {
    min-width: 265px;
    padding-right: 9px;
    transition-delay: .0s;
  }

  .main-menu-item[aria-haspopup="true"] .k-menu-link:before {
    opacity: 0;
  }

  .main-menu-item .k-menu-expand-arrow {
    display: block;
  }
}

.main-menu-item.k-menu-item.k-item {
  color: #000 !important;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  .k-menu-link {
    padding-right: 8px;
  }

  &:hover .k-menu-link,
  .k-menu-link.k-active {
    background-color: #f0f0f0;
    color: #000;
  }

  .k-icon:first-child {
    display: none;
  }

  .k-menu-expand-arrow .k-icon {
    display: block;
  }

  &.k-selected {
    .k-menu-link {
      background-color: $fxPrimary;
      color: #fff !important;
    }
  }
}

.main-menu-item .k-menu-link {
  padding-right: 16px;
}

.profile-menu-item {
  border-bottom: 1px solid #ccc;

  .mdi {
    color: $fxPrimary;
  }

  &.k-selected .mdi {
    color: #fff;
  }
}
