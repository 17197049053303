@import '../../assets/styles/colors.scss';

$selectedRowBG: #e4f2fd;

.ViewRow {
  display: flex;
  align-items: center;
  padding: 4px;
}

.SaveCurrentViewPanel {
  height: var(--ag-header-height);
  border-bottom: var(--ag-tool-panel-separator-border);
  box-sizing: border-box;
}

.PredefinedViewBtn {
  width: 100%;
  justify-content: flex-start;
  color: var(--kendo-color-primary, #2196f3);
  font-weight: 500;
}

.CustomViewsExpansionPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: none !important;

  :global(.k-animation-container) {
    overflow: auto;
  }

  &.CustomViewSelected {
    :global(.k-expander-header) {
      background: $selectedRowBG
    }
  }
}

.ViewRowSelected {
  background: $selectedRowBG;
}

.AdditionalViewNameBtn {
  width: 100%;
  justify-content: flex-start;
  white-space: normal;
  text-align: left;
}

.EditViewNameBtn {
  :global(.mdi) {
    color: #c4c4c5;
  }
}


.CurrencyCellContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CenterCellContent {
  text-align: center;
  justify-content: center;
}

.THWithArrow {
  border-bottom: 2px solid;
}

.THWithArrowInner {
  display: flex;
  align-items: center;
  gap: 2px
}

.THArrow {
  font-size: 26px;
  line-height: 1;
}

.THArrowBlack {
  :global(.mdi) {
    color: #333b48;
  }

  border-color: #333b48;
}

.THArrowGreyPink {
  border-color: #948197;

  :global(.mdi) {
    color: #948197;
  }

}

.THArrowBlue {
  border-color: #48a4d7;

  :global(.mdi) {
    color: #48a4d7;
  }
}

.THArrowGreenBlue {
  border-color: #47abb7;

  :global(.mdi) {
    color: #47abb7;
  }
}

.THArrowOrange {
  border-color: #f4a24e;

  :global(.mdi) {
    color: #f4a24e;
  }
}

.THArrowRed {
  border-color: #f16f79;

  :global(.mdi) {
    color: #f16f79;
  }
}

.NotApplicableMilestoneCell {
  color: #888;

  &:empty:before {
    content: 'N/A';
  }
}

.ActionsContainer {
  height: 100%;
  display: flex;;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  line-height: 1;
}
