.Toolbar {
  flex: 0 0 auto;
  position: relative;
  padding-right: 80px;
}

.ToolbarRight {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  gap: 8px;
  align-items: center;
  padding: 8px;
}

.ContentWrap {
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100%;

  &.HiddenTab {
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0;
    pointer-events: none;
  }
}

.Content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ButtonGroup {
  flex-wrap: wrap;
  max-width: 100%;
}
