@import '../../../assets/styles/colors.scss';

.Modal {
  display: none;

  &.ModalActive {
    display: block;
  }

  &Wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
  }

  &Type {
    &-info :global(.k-dialog-titlebar) {
      background-color: $fxPrimary;
      color: #fff;
    }

    &-error :global(.k-dialog-titlebar) {
      background-color: $error;
      color: #fff;
    }

    &-warning :global(.k-dialog-titlebar) {
      background-color: $warning;
      color: #fff;
    }

    &-success :global(.k-dialog-titlebar) {
      background-color: $success;
    }
  }
}

.ExpandedDialog {
  margin-top: 8px;
  width: 100%;

  :global(.k-expander-title) {
    text-transform: none;
  }

  :global(.k-expander-header),
  :global(.k-expander-content) {
    padding: 1px 4px;
  }

  :global(.content) {
    overflow: auto;
    max-height: 200px;
  }
}
