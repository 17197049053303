@import '../../../assets/styles/colors.scss';

.Container {
  height: auto;
}

.Grid {
  :global(.k-filter-row) {
    display: none;
  }
}