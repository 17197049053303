$fxPrimary: #2196f3;
$secondary: #87cefa;
$error: #e84c3d;
$warning: #ff9d09;
$attention: #dabf00;
$success: #00bd00;
$linkColor: #0072c6;
$linkOnDarkColor: #2096f3;
$grey: #c1c1c1;
$lightgrey: #f4f4f4;
$text: #424242;
$font08rem: 9.6px;
$fxColor: #0cb06f;
