.Container {
  display: flex;
  padding: 8px;
  color: #656565;
}

.Column {
  box-sizing: border-box;
  padding-right: 16px;
}

.ColumnRight {
  min-width: 350px;
}

.Row {
  display: flex;
}

.Label {
  width: 110px;
  font-weight: 700;
  text-transform: uppercase;
}

.Value {
  max-width: 320px;
  min-width: 65px;
}

.Description {
  max-width: 430px;
  white-space: pre-wrap;
}

.Block {
  margin-bottom: 20px;
  line-height: 1.5;
}

.Column:nth-child(2) {
  .Label {
    width: 135px;
  }

  .Financial {
    .Label {
      width: 145px;
    }

    .Block {
      margin-bottom: 8px;
    }
  }
}
