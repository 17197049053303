@import '../../assets/styles/colors.scss';

.TCAdjustmentComment {
  margin-bottom: 12px;
  padding-left: 9px;
  color: #555;
}

.GapsIslandsWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.GapsIslandsStateCode {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.GapsIslands {
  width: 12px;
  border-radius: 2px;
  box-sizing: border-box;
  color: #d3cfcf;
  display: flex;
  align-items: center;
  position: relative;
}

.TotalDurations {
  display: flex;
  margin-top: -8px;
  margin-bottom: 12px;
  font-size: 10px;
  text-align: center;

  :global(.k-chip) {
    cursor: text;
    box-shadow: none !important;
    user-select: initial;
    margin-top: 2px;
    min-width: 44px;
  }
}

.TotalDurationsCell {
  display: flex;
  width: 65px;
  flex-direction: column;
  align-items: center;
}

.TotalDurationsName {
  height: 20px;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 50px;
}

.OriginalTotalDurations {
  flex-direction: row-reverse;
}

.ActiveRequestRow,
.ImportantAuditResultRow,
.NotConfirmedAttestationsRow {
  background: rgba(red, 0.1) !important;
  color: var(--kendo-color-error-on-subtle, #7a0c00);
}