@import '../../../assets/styles/colors.scss';

.Container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.MapContainer {
  flex: 1;
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
}

.CustomMapControls {
  position: absolute;
  top: 102px;
  left: 10px;
  z-index: 109;
}

.CustomSearchMapControls {
  position: absolute;
  top: 218px;
  left: 10px;
  z-index: 109;
}

.LastStopsControl {
  position: absolute;
  top: 257px;
  left: 10px;
  z-index: 109;
}

.Toolbar {
  padding: 5px;
  height: 46px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ToolbarRow {
  height: 16px;
}

.RightContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Radius {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.RadiusLabel {
  padding: 5px;
}

.Left {
  width: 50%;
  padding-right: 4px;
  box-sizing: border-box;
  flex: 0 0 auto;
}

.Right {
  width: 50%;
  padding-left: 4px;
  box-sizing: border-box;
  flex: 0 0 auto;
}

.NewAddress {
  color: $fxColor;
}
