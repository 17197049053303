.Window {
  max-height: 100vh;

  :global(.k-window-actions) button:nth-last-child(3) {
    display: none;
  }

  :global(.k-content) {
    display: flex;
    flex-direction: column;
  }
}

.WindowContent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
