.CalendarTopLevel {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 52px auto 1fr;

  .MapHidden {
    display: none;
  }

  .MapContainer {
    position: relative;
    overflow: hidden;
  }

  .CalendarFiltersContainer {
    display: grid;
    grid-template-rows: 0fr;
    transition-duration: 300ms;
    transition-property: grid-template-rows;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &.CalendarFiltersContainerExpanded {
      grid-template-rows: 1fr;
    }
  }
}

.CalendarContextMenu {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #f7f5f5;
  border: 1px solid #ccc;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  .CalendarContextMenuItem {
    padding: .5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &:active {
      color: #fff;
    }
  }
}

@mixin title-icon {
  font-size: 13px;
  border-radius: 50%;
  line-height: 1;
  background: white;
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.CalendarEvents {
  display: flex;
  flex-direction: column;
  row-gap: .25rem;

  .CalendarEvent {
    border-radius: 3px;
    border: 1px solid #3a87ad;
    font-size: 1.1rem;
    margin: .25rem;
    padding: 0 1px;
    background-color: #3a87ad;
    cursor: pointer;

    .CalendarEventTitle {
      margin: 0.25rem;
      color: #fff;
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 15px;

      .PublishCheckbox {
        margin: 0 .5rem 0 0;
      }

      .ExclamationIcon {
        @include title-icon;
        color: #f00;
      }

      .OvernightIcon {
        @include title-icon;
        color: #060079;
      }

      .CertificateIcon {
        @include title-icon;
        color: #c90101;
      }

      .CertificateIcon {
        @include title-icon;
        color: #c90101;
      }

      .CameraIcon {
        @include title-icon;
        color: #060079;
      }
    }

    .CalendarEventContent {
      background-color: #f8f8f8;
      padding: .1rem;
      margin: 0 0 1px 0;
      color: #000;
    }

    .BusyResource {
      font-weight: 700;
    }

    &.NotAvailable {
      background: repeating-linear-gradient(-45deg, whitesmoke, whitesmoke 5px, #e4e4e4 5px, #e4e4e4 10px);
      border-color: whitesmoke;

      .CalendarEventTitle {
        border-color: #ddd;
        color: #000;
      }
    }

    &.Available {
      background-color: #CFFCCF;
      border-color: #CFFCCF;

      .CalendarEventTitle {
        color: #000;
      }

      svg {
        stroke: #000;
      }

      .Limited {
        color: red;
        font-weight: 700;
        margin-right: 4px;
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
      }
    }

    &.PastChanged {
      background: repeating-linear-gradient(
                      -45deg,
                      #2351dc,
                      #2351dc 5px,
                      RoyalBlue 5px,
                      RoyalBlue 10px
      );
    }

    &.Published {
      background-color: RoyalBlue;
    }

    &.Scheduled {
      background-color: DarkOrange;
    }

    &.Completed {
      background-color: Green;
    }

    &.Cancelled {
      background-color: #B0B0B0;
    }
  }
}

.DispatchTooltipContainer {
  .DispatchTooltip {
    padding: 0;
    background-color: #E5F6FE;
  }

  .DispatchTooltipInner {
    background-color: #E5F6FE;
    border-color: #ADD9ED;
    color: #5E99BD;
    max-width: 300px;

    .DispatchTooltipTitle {
      background-color: #D0E9F5;
      padding: 5px 35px 5px 10px;
      border-width: 0 0 1px;
      font-weight: 700;
    }

    .DispatchTooltipContent {
      font-size: 1rem;
      line-height: 1rem;
      padding: 5px;
      text-align: left;
      word-wrap: break-word;

      p {
        margin: .25rem;
      }
    }
  }
}

.LegendContainerTop {
  height: 100%;
  position: relative;

  .LegendContainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    position: relative;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    justify-items: center;

    & > div {
      width: 1rem;
      height: 1rem;
      float: left;
      cursor: initial;

      &:nth-of-type(1) {
        background-color: DarkOrange;
      }

      &:nth-of-type(2) {
        background-color: RoyalBlue;
      }

      &:nth-of-type(3) {
        background-color: Green;
      }

      &:nth-of-type(4) {
        background-color: #b0b0b0;
      }
    }
  }
}

.CalendarToolbar {
  padding: 0;
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .CalendarToolbarTitle {
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    justify-content: center;
  }
}

.CollapsibleTitle {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .CollapsibleTitleContent {
    flex: 1;
  }
}

.CollapsibleIcon {
  cursor: pointer;
  display: flex;
  padding: 0 .25rem;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: inline-block;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  &.CollapsibleIconExpanded svg {
    transform: translate(0, 0) rotate(-180deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
  }
}

.CollapsibleContent {
  display: grid;
  grid-template-rows: 0fr;
  transition-duration: 300ms;
  transition-property: grid-template-rows;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &.CollapsibleContentExpanded {
    grid-template-rows: 1fr;
  }

  & > div:first-of-type {
    overflow: hidden;
  }
}

.Recordlink,
.Recordlink:hover {
  color: #0066CC;
}

.CalendarFiltersTop {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: .5rem;
  row-gap: .25rem;
  border-top: none;
  padding: 0;

  .CalendarFilters {
    display: flex;
    flex-wrap: nowrap;
    column-gap: .5rem;
    row-gap: .25rem;
    align-items: flex-start;
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
  }
}

.CalendarDayView {
  overflow: hidden auto;
  height: 100%;

  .CalendarDayViewTable {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;

    > thead {
      border-color: #ddd;

      th {
        border-style: dashed;
        border-width: 0 0 1px 0;
        vertical-align: bottom;
        padding: 3px;
        text-align: center;

        &:first-of-type,
        &:last-of-type {
          width: 18px;
        }
      }
    }

    > tbody {
      tr {
        cursor: pointer;

        &:nth-of-type(even) {
          background-color: #f3f3f3;
        }

        td {
          text-align: center;

          .CalendarDayOnSite {
            border-radius: 50%;
            background-color: rgb(24, 202, 113);
            height: 5px;
            width: 5px;
            margin: 0.5rem 0 0 0.5rem;
          }
        }
      }
    }

    .CalendarDayCorrect {
      color: green;
    }

    .CalendarDayIncorrect {
      color: red;
    }
  }
}

.CalendarDayCommentTooltipContainer {
  .CalendarDayCommentTooltip {
    background-color: #fcdb3b;
    color: black;
  }
}

.CalendarMapView {
  z-index: 10001;
  position: relative;
  height: 100%;
}

.CalendarMonthViewContainer {
  height: 100%;
  overflow: hidden;

  .CalendarMonthView {
    position: relative;
    height: 100%;
    overflow: hidden auto;

    & > .CalendarMonthViewTable {
      width: 100%;
      border-collapse: collapse;
      box-sizing: border-box;
      font-size: 1rem;

      & > thead tr {
        th {
          padding: .5rem;
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;
          width: 14%;

          &:first-of-type {
            width: auto;
          }
        }
      }

      & > tbody tr {
        td {
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;

          &:first-of-type {
            text-align: center;
            vertical-align: top;
            padding: .25rem;
          }

          &.CalendarMonthViewCell {
            vertical-align: top;

            &.CalendarMonthViewCellDisabled {
              background: #d7d7d7;
              opacity: .3;
            }

            &.CalendarMonthViewCellToday {
              background-color: #fcf8e3;
            }
          }
        }

        .CalendarMonthViewDay {
          .CalendarMonthViewDayTop {
            text-align: right;
            padding: .25rem;
          }
        }
      }
    }
  }
}

.CalendarResourcesViewContainer {
  height: 100%;
  overflow: hidden;

  .CalendarResourcesView {
    position: relative;
    height: 100%;
    overflow: hidden auto;

    & > .CalendarResourcesViewTable {
      width: 100%;
      border-collapse: collapse;
      box-sizing: border-box;
      font-size: 1rem;

      & > thead tr {
        th {
          padding: .5rem;
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;
          width: 12%;

          &:nth-of-type(2) {
            width: 20px;
          }
        }
      }

      & > tbody tr {
        &:hover {
          background-color: #f5f5f5;
        }

        td {
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;
          text-align: center;

          &:first-of-type {
            text-align: left;
            vertical-align: top;
            padding: .25rem;
          }

          &.CalendarResourcesViewPeriodHoursExceeded {
            color: #F00;
          }

          .CalendarResourcesViewCell {
            display: flex;
            align-items: center;
            column-gap: .25rem;

            & > div:first-of-type {
              min-width: 3rem;
            }

            .CalendarResourcesViewEvents {
              display: flex;
              flex-direction: column;
              row-gap: 0.25rem;
            }

            .CalendarResourcesViewEvent {
              border-radius: 3px;
              border: 1px solid #3a87ad;
              font-size: 1.1rem;
              padding: .1rem .1rem .2rem .1rem;
              background-color: #3a87ad;
              cursor: pointer;
              color: #fff;
              line-height: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;

              &.CalendarResourcesViewEventPublished {
                background-color: RoyalBlue;
              }

              &.CalendarResourcesViewEventScheduled {
                background-color: DarkOrange;
              }

              &.CalendarResourcesViewEventCompleted {
                background-color: Green;
              }

              &.CalendarResourcesViewEventCancelled {
                background-color: #B0B0B0;
              }
            }
          }
        }
      }
    }
  }
}

.CalendarWeekViewContainer {
  height: 100%;
  overflow: hidden;

  .CalendarWeekView {
    position: relative;
    height: 100%;
    overflow: hidden auto;

    & > .CalendarWeekViewTable {
      width: 100%;
      border-collapse: collapse;
      box-sizing: border-box;
      font-size: 1rem;

      & > thead tr {
        th {
          padding: .5rem;
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;
          width: 14%;

          &:first-of-type {
            width: auto;
          }
        }
      }

      & > tbody tr {
        td {
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;

          &:first-of-type {
            text-align: center;
            vertical-align: top;
            padding: .25rem;
          }

          &.CalendarWeekViewCell {
            vertical-align: top;

            &.CalendarWeekViewCellDisabled {
              background: #d7d7d7;
              opacity: .3;
            }

            &.CalendarWeekViewCellToday {
              background-color: #fcf8e3;
            }
          }
        }

        .CalendarWeekViewDay {
          .CalendarWeekViewDayTop {
            display: flex;
            flex-direction: row-reverse;
            padding: .25rem;
          }
        }
      }
    }
  }
}
