@import '../../assets/styles/colors.scss';

.ButtonGroup {
  box-sizing: border-box;
  flex-wrap: wrap;

  :global(.k-button) {
    width: 95px !important;
    flex: 0 0 auto;
    margin: 0 !important;
  }

  :global(.k-button):nth-child(1) {
    margin-bottom: 8px !important;
  }
}

.ViewContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.ViewContainerActive {
  z-index: 1;
  opacity: 1;
}
