@import '../../assets/styles/colors.scss';

.Container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow-x: auto;
  height: 100%;
}

.Toolbar {
  flex: 0 0 auto;
}

.ToolbarContent {
  width: 100% !important;
  flex-wrap: wrap;
  margin-right: 0 !important;

  :global(.k-checkbox-label) {
    margin-right: 8px;
  }
}

.ToolbarRow {
  display: flex;
  width: 100%;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  :global(.k-widget) {
    margin-right: 8px;
  }
}

.ToolbarCombobox {
  width: 200px !important;
  margin-right: 8px;

  &.ToolbarLargeCombobox {
    width: 300px !important;
  }

  &.ToolbarSmallCombobox {
    width: 165px !important;
  }
}

.ToolbarInfoLabel {
  width: 65px;
}

.ChipCounter {
  width: 55px;
  text-align: center;
  font-size: 12px;
  margin: 0 8px;
}

.GroupRow {
  display: flex;
  align-items: center;
  height: 15px;
}

.GroupRowCell {
  margin-left: 8px;
}

.SectionTd {
  font-weight: 700 !important;
  background-color: #ededed;
}

.SectionTdExport {
  font-weight: 700 !important;
  background: #fff;
  border: 1px solid #ededed !important;
}

.BPTd {
  background: rgba($fxPrimary, 0.3);
}

.BPTdExport {
  background: #fff;
  border-bottom: 1px solid #c3c3c3 !important;
  font-weight: 700 !important;
}

.BPLink {
  margin-left: 8px;
  font-weight: 700;
}

.TdExport {
  background: #fff;
  position: relative;
}

.Grid {
  min-width: 1000px;
  width: 100%;

  table {
    width: 100% !important;
  }

  :global(.k-alt:not(:hover)) {
    background: transparent !important;
  }

  :global(.k-grid-header .k-header) {
    justify-content: center;
  }
}

.RightBtn {
  margin-left: 8px;
}

.ProgressBar {
  width: 100%;
  height: 25px;
  background: transparent;
  border-radius: 0;

  :global(.k-progress-status) {
    width: 100%;
  }

  :global(.k-selected) {
    color: #656565;
    background-color: rgba($success, 0.25);
    height: 25px !important;
  }
}

.ProgressBarOver {
  :global(.k-selected) {
    background-color: rgba($error, 0.25);
  }
}

.MarkedColumn {
  background-color: rgba($fxPrimary, 0.25) !important;
}

.MarkedColumnExport {
  background: linear-gradient(90deg, #c7e5fc 0%, #fff 15%, #fff 75%, #c7e5fc 100%)
}

.ExportGrid {
  td {
    border-width: 0 0 1px 1px;
    background: #fff !important;
  }
}

.RedNumberExport {
  position: absolute;
  left: 0;
  top: 0px;
  font-weight: 700;
  width: 5px;
  height: 15px;
  // border: 1px solid;
  // border-radius: 50%;
  text-align: center;
  box-sizing: border-box;
  line-height: 13px;
}

.ExportProgress {
  position: absolute;
  left: 0;
  height: 1px;
  top: -1px;
  background-color: #666;
}

.ButtonGroup {
  width: 100%;
  padding-right: 8px;
  box-sizing: border-box;
}

.TotalChartDetailsGrid {
  :global(.k-grid-header) {
    display: none;
  }
}

.ChartDetailsGrid {
  // flex: 1 !important;
  // min-height: 50vh;
}

.GroupRowCellBold {
  font-weight: 700;
}

.GroupRowCellBoldExport {
  margin-right: 4px;
}
