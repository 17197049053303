@import '../../../assets/styles/colors.scss';

.Splitter {
  height: 100%;
}

.RightPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.Toolbar {
  flex: 0 0 auto;
  align-items: flex-start;
  flex-wrap: wrap;
}

.GroupTD {
  display: flex;
  align-items: center;
}

.GroupTDNumbers {
  margin: 0 8px;
}

.GroupTDCheckbox {
  // margin-left: auto;
}

.CLMFilterRow {
  width: 100%;
  display: flex;
  margin-right: 0 !important;
  align-items: center;
}

.Grid table {
  width: 100% !important;
}

.PreviewerCarouselBox {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
}

.HistoryPanel {
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  width: 100%;
  position: relative;
}

.History {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.HistoryEntry {
  color: grey;
  border-bottom: 1px dashed;
  font-size: 11px;
  flex: 0 0 auto;
}

.HistoryEntryRow {
  display: flex;
}

.HistoryEntrySrc {
  margin-left: auto;
  font-weight: 700;
}

.HistoryList {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  margin-top: 8px;
}

.HistoryPanelTitle {
  font-weight: 700;
  margin: 8px 0;
}

.HistoryPanelBtn {
  width: 100%;
  margin-top: 8px;
}

.PreviewList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 0 4px;
  box-sizing: border-box;
  height: auto;
  flex: 0 0 auto;
}

.PreviewItem {
  width: auto;
  height: 50px;
  margin: 4px;
  box-shadow: 0 0 3px #000;
  cursor: pointer;
  opacity: 0.6;

  &.PreviewItemActive, &:hover {
    opacity: 1;
  }
}

.ResultsNav {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: space-between;
}

.CLIComments {
  max-height: 30vh;
  overflow: auto;
  width: 100%;
  flex: 0 0 auto;
  display: block;
}

.DownloadButton {
  width: 98px;
  margin-left: 8px;
}

.DownloadGroupButton {
  margin-left: 12px;

  :global(.k-icon) {
    margin: 0 !important;
  }
}

.MobileListActionBar {
  display: flex;
  align-items: center;
  margin-top: 8px;

  :global(.k-button) {
    margin-left: 4px;
  }
}

.BottomToolbarBtns {
  :global(.k-button) {
    margin: 0 8px;
  }
}

.MobileSingleRowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MobileWO {
  text-align: right;
  margin-top: auto;
}

.MobileToolbar {
  :global(.k-button) {
    padding: 8px;
    font-size: 16px;
    height: 40px;
  }

  :global(.k-button-icon) {
    font-size: 20px;
  }

  :global(.k-icon-button) {
    width: 40px;
  }

  :global(.k-clear-value) {
    width: 40px;
    height: 40px;

    :global(.k-icon) {
      font-size: 20px;
    }
  }
}

.MobileGroupSwitch {
  width: 110px;
  margin: 0 4px;
}

.MobileComboboxPopup {
  :global(.k-list-item) {
    height: 40px;
  }
}

.PopupPreview {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
}
