@import '../../assets/styles/colors.scss';

:root {
  --text-color: $text;
}

.ScreenHeightContainerWithToolbar {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  :global(.k-toolbar) {
    flex: 0 0 auto;
  }
}

.ScrollableContainer {
  flex: 1;
  overflow: auto;
}

.TextCenter {
  text-align: center !important;
}

.TextRight {
  text-align: right !important;
}

.SmallKendoGrid {
  th,
  td {
    padding: 4px 6px;
  }

  th {
    height: 12px;
  }

  :global(.k-grouping-row .k-icon) {
    margin-left: calc(8px - 12px);
    margin-right: 0px;
  }

  :global(.k-grouping-row td:first-child) {
    column-span: 2;
  }
}
