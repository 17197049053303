@import '../../assets/styles/colors.scss';

.Container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow-x: auto;
}

.ToolbarCombobox {
  width: 200px !important;
  margin-right: 8px;

  &.ToolbarLargeCombobox {
    width: 300px !important;
  }
}

.ToolbarInfoLabel {
  width: 65px;
}

.GridDatepicker {
  width: 100%;
}

.GroupTitle {
  display: inline-block;
  padding-right: 4px;
  box-sizing: border-box;

  &:nth-child(1) {
    width: 85px;
  }
}

.GroupRow {
  display: flex;
  align-items: center;
  height: 35px;
  box-sizing: border-box;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 700;

  :global(.k-icon) {
    margin-right: 0 !important;
  }
}

.GroupCountsRow {
  .GroupTitle:not(:nth-child(1)) {
    width: auto;
  }
}

.GroupTd {
  border-top-width: 0 !important;
  background-color: #ededed;
}

.GroupSmallField {
  width: 40px;
}

.GroupCombobox {
  width: 250px;
}

.GroupSmallField,
.GroupCombobox {
  margin-right: 6px;
}

.GroupComment {
  flex: 1;
}

.RedDateCell {
  color: red;

  input {
    color: red;
  }
}

.FilterCellCombobox {
  width: 100% !important;
}

.MilestonesGrid {
  min-width: 1000px;
  width: 100%;

  table {
    width: 100% !important;
  }
}

.RightBtn {
  margin-left: 8px;
}

.MilestonesGridNoGrouped {
  .GroupCombobox {
    margin: 0;
    width: 100%;
  }

  .GroupComment {
    width: 100%;
  }

  :global(.k-grid-header .k-header > .k-link) {
    white-space: pre-wrap;
  }
}

.DetailsMultiSelect {
  width: 135px !important;
  margin-left: 8px;
}

.MSMultiSelect {
  width: 190px !important;
}

.naCell {
  color: $grey;
}

.BPStatusCommentBox {
  position: relative;
  flex: 1;
  display: flex;
}

.BPStatusCommentBtns {
  display: flex;
}

.BPCommentCell {
  display: flex;
  align-items: center;
  gap: 2px;
}

.BPCommentCellText {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BPCommentCellBtn {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}
