.Chat {
  height: 100%;
  max-width: 600px;

  :global(.k-message-box) {
    display: none;
  }

  :global(.k-message-list),
  :global(.k-message-list-content) {
    width: 600px;
  }

  /* :global(.k-message) {
    width: 100%;
  }
  :global(.k-message-time) {
    position: absolute;
    bottom: -15px !important;
    right: 5px !important;
    position: absolute;
    transform: none !important;
    left: auto !important;
    top: auto !important;
  } */
}
