@import '../../assets/styles/colors.scss';

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.GridContainer {
  flex: 1 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.CurrencyBox {
  position: relative;
  box-sizing: border-box;
  padding-left: 10px;
}

.Currency {
  position: absolute;
  left: 0;
  top: 0;
}

.PLGrid {
  th {
    &:global(.k-header) {
      white-space: normal;
    }
  }

  td {
    &:global(.Balance),
    &:global(.WIPAdjustedProfitMargin),
    &:global(.FirstRow) {
      background-color: rgba($fxPrimary, 0.25);
    }
  }

  &.PLGridCollapse {
    :global(.k-grid-container tr:not(.k-selected)),
    :global(.k-grid-header tr.k-filter-row),
    :global(.k-grid-footer) {
      display: none;
    }
  }

  :global(.k-numeric-wrap .k-select) {
    display: none;
  }
}

.Summary {
  box-sizing: border-box;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.PLGrid {
  th {
    white-space: normal;
  }

  &.PLGridCollapse {
    :global(.k-grid-container tr:not(.k-selected)) {
      display: none;
    }

    :global(.k-grid-footer) {
      display: none;
    }
  }
}

.SummaryKendoGrid {
  tr {
    height: 26px !important;
    background: transparent !important;

    &.ColoredRow {
      background: $fxPrimary !important;
      background: $secondary !important;
      background: rgba(33, 150, 243, 0.25) !important;
    }
  }

  td {
    padding: 4px !important;
    border-left: none;
  }

  :global(.k-grid-header) {
    display: none;
  }
}

.HiddenMode {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  top: -9999;
  left: -9999;
}
