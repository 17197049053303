@import '../../../assets/styles/colors';

.Container {
  margin: 0 -4px;
  padding: 4px;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;

  &:hover .Actions {
    visibility: visible;
  }

  &:hover .Reviewer {
    visibility: visible;
  }
}

.SelectedTC, .MinMode {
  .Actions, .Reviewer {
    visibility: visible;
  }
}

.CompareMode {
  background: rgba(#000, 0.05);
}

.Content {
  display: flex;
  justify-content: space-between;
}

.LeftCol {
  width: 75%;
  max-width: 750px;
}

.Row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.ChipsRow {
  justify-content: flex-end;
  flex-wrap: wrap;
  height: 55px;
  align-items: flex-start;
  box-sizing: border-box;

  :global(.k-chip.k-disabled) {
    opacity: 1;
  }
}

.EmployeeContainer {
  width: 250px;
  display: flex;
  align-items: center;
}

.CrewLead {
  font-size: 10px;
  color: #555;
}

.EmployeeName {
  text-overflow: ellipsis;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 9px;
  visibility: hidden;
}

.Check {
  display: flex;
  justify-content: flex-end;
  width: 22px;
}

.CompareCheckIcon {
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  color: #999;
}

.Date {
  width: 80px;
}

.Status {
  border: 1px solid;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  color: grey;
  text-align: center;
  width: 100px;
  box-sizing: border-box;
  line-height: 20px;
  height: 22px;
}


.Reviewer {
  font-size: 12px;
  color: grey;
  visibility: hidden;
}

.ClockInOut {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: grey;
}

.TimeFormula {
  font-size: 12px;
  color: grey;
  display: flex;
  gap: 6px;
}

.DispatchRow {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;

  &:hover {
    background: #f1f1f1;
  }
}

.DispatchName {
  flex: 1;
  cursor: default;
}

.Chat {
  z-index: 29999 !important;

  :global(.k-message-box) {
    display: none;
  }
}

.ChatContainer {
  margin: -4px;
  height: 500px;
  width: 500px;
  max-height: 100vh;
  position: relative;

  :global(.k-chat) {
    border: none;
  }
}

.ChatBtn {
  position: relative;
  margin-left: 8px;
  width: 21px;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  cursor: pointer;
}

.ChatIcon {
  position: absolute;
  left: -4px;
  top: 0;
  font-size: 29px;
  color: rgba(black, 0.1);
  transition: color 0.3s;
}

.ChatCount {
  position: relative;
  color: rgba(black, 0.4);
  z-index: 1;
  transition: color 0.3s;
}

.ChatBtn:hover, .ChatBtnActive {
  .ChatIcon {
    color: rgba(black, 0.2);
  }

  .ChatCount {
    color: rgba(black, 0.5);
  }
}

.ChatBtnRed {
  .ChatCount {
    color: #555;
  }

  .ChatIcon {
    color: rgba(red, 0.2);
  }

  &:hover {
    .ChatIcon {
      color: rgba(red, 0.25);
    }
  }
}

.ChatEmployeeComment {
  background: #fcddda !important;
  border: 1px solid #f76f60 !important;
}

.ChatDescription {
  padding: 4px 0;
  font-size: 10px;
  font-style: italic;
  color: grey;
}

.GpsChips {
  /*background: #ff4b4b;
  color: #ffffff;*/
  /*background: #ffdb99;
  color: #543c10;
  border-color: #ff0000;*/

  background: #ffdb99;
  border-color: #ff7300;
  color: #5d3c00;
}