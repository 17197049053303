@import '../../assets/styles/colors.scss';

.AuthContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 99998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AuthForm {
  position: relative;
  width: 320px;
  max-height: 90vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4px
}

.AuthFormList {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  scrollbar-width: thin;
  padding: 8px;

  &.AuthFormListCompact {
    flex: auto;
    max-height: 400px;
  }
}

.AuthSubmit {
  color: #fff !important;
  background: $fxColor !important;
  border-color: $fxColor !important;
}

.AuthSubmit,
.AuthConterra {
  width: 100%;
}

.AuthLogo {
  width: 170px;
  height: 34.92px;
  margin: 0 auto 15px;
  display: block;
}

.AuthMessage {
  text-align: right;
  padding: 8px 0;
  box-sizing: border-box;
  height: 32px;
  color: $error;
  font-size: 12px;
}

.k-textbox-container input:-webkit-autofill ~ label /* FIX */
{
  top: 0 !important;
  left: 0 !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}

.AuthLastLoginUser {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0 2px 8px;

  &:hover {
    background: #fbfbfb;
  }
}
