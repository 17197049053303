@import '../../assets/styles/colors.scss';

.ContentWrap {
  flex: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;

  &.HiddenTab {
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0;
    pointer-events: none;
  }
}

.TabBtnBadge {
  z-index: 6;
  position: relative;
  top: -1px;
  margin-left: 4px;
  font-size: 10px;
  line-height: 16px;
  padding: 0 1px;
  min-width: 16px;
  height: 16px;
  display: inline-block;
}