.Attachment {
  height: 50px;
  padding: 0 8px;
  border: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.AttachmentIcon {
  margin-right: 4px;
  font-size: 25px;
}

.AttachmentAsLink {
  cursor: pointer;

  .AttachmentText {
    color: #0072c6;
  }

  &:hover {
    .AttachmentText {
      text-decoration: underline;
    }
  }
}

.TextIframe {
  width: 100%;
  height: 100%;
  border: 0;
  display: block;
}

.AttachmentsContainer {
  padding: 8px 0;
}
