.last-point-icon {
  position: absolute;
  bottom: 7px;
  left: 50%;
  background: #fff;
  border: 2px solid;
  border-radius: 5px;
  transform: translateX(-50%);
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  box-shadow: black 0 0 5px;
  color: #fff;
}

.last-point-icon__gps {
  text-align: center;
  color: red;
  font-size: 10px;
  border-bottom: 1px solid #444;
  font-weight: 700;
  padding: 0 3px;
}

.last-point-icon__content {
  color: #444;
  padding: 1px 3px;
  min-height: 7px;
  white-space: nowrap;
}

.last-point-icon__number {
  display: block;
  font-size: 13px;
  margin-right: 5px;
  line-height: 1;
}

.last-point-icon__number,
.last-point-icon__employees {
  display: inline-block;
  vertical-align: middle;
}

.last-point-icon__content.no-employees {
  padding: 4px 0;
  min-width: 25px;
}

.last-point-icon__content.no-employees .last-point-icon__number {
  margin: 0;
}

.last-point-icon:after {
  content: '';
  position: absolute;
  bottom: -7px;
  border-top: 7px solid;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  margin-left: -10px;
  left: 50%;
}

/*CLOCK IN/OUT MAP ICON*/
.flag-icon {
  height: 35px;
  min-width: 50px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 2px solid;
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  position: relative;
}

.flag-icon.start {
  background: #369831;
}

.flag-icon.end {
  background: #bc1824;
}

.flag-icon:before,
.flag-icon:after {
  content: '';
  position: absolute;
}

.flag-icon.end:after {
  left: 0px;
  bottom: -10px;
  border-top: 12px solid #bc1824;
  border-right: 12px solid transparent;
}

.flag-icon.end:before {
  left: -2px;
  bottom: -14px;
  border-top: 14px solid #ffffff;
  border-right: 14px solid transparent;
}

.flag-icon.start:after {
  right: 0px;
  bottom: -10px;
  border-top: 12px solid #369831;
  border-left: 12px solid transparent;
}

.flag-icon.start:before {
  right: -2px;
  bottom: -14px;
  border-top: 14px solid #ffffff;
  border-left: 14px solid transparent;
}

/**/
@keyframes last-stop-animate {
  0% {
    bottom: 9px;
  }
  100% {
    bottom: 15px;
  }
}

.last-stop-animated {
  animation: 500ms linear infinite alternate last-stop-animate;
}

@keyframes site-animate {
  0% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

.site-animated {
  animation: 500ms linear infinite alternate site-animate;
}

.details-group {
  font-size: 11px;
  padding: 2px 0;
}

.details-group:not(:first-child) {
  border-top: 1px solid;
}

.marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

.marker-cluster-small div {
  background-color: rgb(110 204 57);
}

.marker-cluster-medium {
  background-color: rgb(241 211 87);
}

.marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

.marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster-other {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid;
  line-height: 1;
}

.leaflet-scroll-popup {
  .leaflet-popup-content {
    margin: 0;
    border-radius: 15px;
    overflow: hidden;
  }
}

.leaflet-scroll-popup-content {
  font-size: 10px;
  max-height: 200px;
  overflow: auto;
  padding: 10px;
}


.playback-time {
  padding: 4px 2px;
  width: 62px;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  bottom: 32px;
  left: 0;
  background: #0075ff;
  color: #fff;
  margin-left: -24px;
  border-radius: 3px;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;

  &:before {
    content: "";
    border: 10px solid transparent;
    position: absolute;
    border-top-color: #0075ff;
    bottom: -9px;
    left: 21px;
    border-bottom-width: 0;
  }
}

.playback-slider-wrapper {
  position: relative;
  height: 20px;
  top: 2px;

  input[type=range] {
    margin: 0;
    width: 100%;
    padding: 0; // fix for ie
  }

  input[type=range]::-ms-tooltip { // fix for ie
    display: none;
  }

  &:hover .playback-time {
    opacity: 1;
    z-index: 1;
  }
}

.playback-slider-container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 900;
  margin-left: 25px !important;
}

.playback-employee {
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}

.show-playback-employee {
  .playback-employee {
    display: block;
  }
}
