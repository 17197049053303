.CanDiasabledBtn {
  // visibility: hidden;
}

.UpdateCell {
  text-align: center;

  &:hover {
    .CanDiasabledBtn {
      visibility: visible;
    }
  }
}

.AlignCenter {
  text-align: center !important;
}
