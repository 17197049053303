//@import 'colors';

.ME {
  position: relative;
  z-index: 1;
  font-size: 1rem;
  height: 30px;
  box-sizing: border-box;
  padding-left: 20px;
}

.METext {
  height: 100%;
  display: flex;
  align-items: center;
  line-height: 1.1;
  word-break: break-all;
  position: relative;
  z-index: 3;
  // display: table;
}

.METExtCell {
  display: table-row;
}

.METimeEntry {
  display: table-cell;
  vertical-align: middle;
  //font-size: $font08rem;

  &:hover {
    color: rgb(113, 146, 179);
  }
}

.MEBefore,
.MEStartBefore,
.MEEndBefore {
  left: 0;
  top: 0;
  bottom: 0;
  width: 14px;
  padding: 0 4px;
  position: absolute;
  box-sizing: border-box;
  // z-index: 2;
  transition: 0.2s;
}

.MEBefore:before,
.MEStartBefore:before,
.MEEndBefore:before {
  content: '';
  border-left: 6px solid #efefef;
  display: block;
  height: 100%;
  opacity: 0.7;
}

.MEBefore.hover:before,
.MEStartBefore.hover:before,
.MEEndBefore.hover:before {
  opacity: 1;
}

.MEBefore.show,
.MEStartBefore.show,
.MEEndBefore.show {
  color: #2096f3;
}

.MEStartBefore {
  top: 50%;
}

.MEEndBefore {
  bottom: 50%;
  z-index: 3;
}

.MEStartBefore .circleBtn,
.MEEndBefore .circleBtn {
  content: '';
  position: absolute;
  left: 1px;
  border: 2px solid;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: border-box;
  color: #2096f3;
  z-index: 9;
  cursor: pointer;
}

.MEEndBefore .circleBtn {
  bottom: -5px;
}

.MEStartBefore .circleBtn {
  top: -7px;
  z-index: 6;
}

.MEEndBefore + .MEStartBefore .circleBtn {
  display: none;
}

.MELine + .MELine:after {
  display: none;
}

.MEMiles {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 14px;
  right: 0;
  bottom: 0;
  padding-left: 6px;
  color: #b5b5b5;
  z-index: 2;
  //font-size: $font08rem;
  box-sizing: border-box;
}
