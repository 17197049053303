@import '../../../assets/styles/colors';

.ToolbarRow {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.DateRangeFilter {
  width: 255px;
  justify-content: space-between;

  :global(.k-dateinput) {
    width: 124px;
    border: none;
  }

  :global(.k-floating-label-container) {
    padding-top: 0;
  }

  :global(.k-floating-label),
  :global(.k-icon-button) {
    display: none;
  }
}

.Card {
  flex-direction: row;
  align-items: center;
  height: 37px;
  padding: 4px;
  gap: 4px;
  border: none;
}

.CheckedNumbers {
  width: 124px;
  display: flex;
  gap: 4px;
  text-align: center;
  line-height: 1;
  cursor: default;
}

.CompareModeBtn {
  &:after {
    opacity: 0 !important;
  }
}

.FilterFlexPopup ul {
  display: flex;
  flex-direction: column;
}

.OpacityListComboboxItem {
  opacity: 0.5;
  order: 1;
}