@import '../../assets/styles/colors.scss';

.DescriptionCell {
  text-align: center;
  position: relative;
  pointer-events: all;
}

.NotesIconBox {
  display: inline-block;
  text-align: center;

  :global(.k-icon) {
    pointer-events: none;
  }
}

.BalanceCell {
  text-align: center;
  position: relative;
  pointer-events: all;
}
