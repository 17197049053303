.ListArrow {
  width: 16px;

  &[disabled] {
    opacity: 0.2;
  }
}

.TabsHeaderRow {
  display: flex;
  align-items: center;
}

.Content {
  flex: 1;
  position: relative;
}

.ContentHidden {
  display: none;
}

.SplitterPanes {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto !important;
}

.TextRight {
  text-align: right !important;
}

.DailyUpdateComment {
  border: 1px solid #c9c9c9;
  padding: 4px;
  white-space: pre-line;
  min-height: 27px;
  box-sizing: border-box;
}

.DailyStatusContent {
  padding: 0 5px 5px;
  flex: 1;
  overflow: auto;
}

.Spacer {
  height: 20px;
}

.ValueOverCell {
  color: red;
}

.Title {
  font-weight: 700;
  text-transform: uppercase;
}

.TotalRow {
  font-weight: 700;
}
