@import '../../../../assets/styles/colors';

.TE {
  display: flex;
  position: relative;
  min-width: 100px;
  box-sizing: border-box;
  font-size: $font08rem;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;

  .WOAllocationBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WOLinkName {
    display: block;
    opacity: 0;
    z-index: -1;
  }

  .WOLinkNumber {
    display: flex;
    align-items: center;
    position: absolute !important;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &:hover {
    .TEText {
      background: #f2f2f2;
    }

    .WOLinkName {
      opacity: 1;
      z-index: 1;
    }

    .WOLinkNumber {
      opacity: 0;
      z-index: -1;
    }
  }
}

.TERowOnHoverLayer {
  position: absolute;
  left: 60px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.TESpace {
  flex: 0 0 auto;
  width: 66px;
  display: flex;
  align-items: center;
}

.TEContent {
  height: 100%;
  position: relative;
  flex: 1;
}

.TEDuration {
  display: inline-block;
  margin-left: 0;
  margin-right: 5px;
  width: 28px;
  flex: 0 0 auto;
}

.TEText {
  word-break: break-all;
  display: flex;
  align-items: center;
  line-height: 1.1;
  height: 100%;
  padding: 0 10px 0 10px;
  margin-right: 0;
  margin-left: -2px;
  justify-content: flex-start;
}

.TEDetailsContent {
  max-width: 350px;
  margin: -4px;
  padding: 4px;
}

.TEDetailsRow {
  display: flex;
  gap: 4px;
}

.TETime {
  position: relative;
  z-index: 1;
  display: block;
  height: 16px;
  font-size: 9.6px;
  margin: -8px 6px -8px 2px;
  color: grey;
  line-height: 16px;
  width: 48px;
  float: left;
  text-align: right;
}

.TECostType {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 6px;
  display: block;
  color: #e0e0e0;
  cursor: pointer;
  transition: 0.2s;
  z-index: 2;
  left: 60px;

  &:hover {
    opacity: 0.7;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: 6px solid;
    display: block;
  }

  &.thin {
    width: 8px;
    left: 59px;
    background: none !important;

    &:before {
      border-left-width: 2px;
      right: 3px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    transition: 0.15s;
    top: 50%;
  }
}

.TE {
  &:global(.stop) {
    .TECostType:after {
      border: 6px solid;
      border-radius: 50%;
      box-shadow: #fff 0 0 0 2px;
      left: -3px;
      margin-top: -6px;
    }

    .TECostType.thin:after {
      left: 0.5px;
      border-width: 4px;
      margin-top: -4px;
    }
  }
}
