@import '../../assets/styles/colors.scss';

.FormField {
  width: 100%;
  margin-bottom: 5px;
  flex: 0 0 auto;
}

.MBO {
  margin-bottom: 0;
}

.FormFieldLabel {
  width: 90px;
  flex: 0 0 auto;
  padding-right: 5px;
  box-sizing: border-box;
  font-weight: 600;
  text-transform: uppercase;
}

.DatePickerLabel {
  width: 90px;
  flex: 0 0 auto;
  padding-right: 5px;
  box-sizing: border-box;
}

.ReadOnlyField {
  width: 100%;
  padding: 5px 0;
  flex: 0 0 auto;
  min-height: 29px;
  box-sizing: border-box;
}

.ReadOnlyTextarea {
  width: 100%;
  padding: 5px 0;
  margin-bottom: 5px;
  max-height: 110px;
  box-sizing: border-box;
  overflow: auto;
  flex: 0 0 auto;
}

.FormTitle {
  margin: 10px 0 5px;
}

.FormWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.FormFooter {
  margin-top: auto;
  padding: 0;
  padding-top: 16px;
  padding-bottom: 8px;
  justify-content: flex-end;
}

.DatePickerRow {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .Checkbox {
    margin-left: 15px;
  }
}

.Datepicker {
  width: 120px;
}

.DateSeparator {
  padding: 0 10px;
}

.CheckboxRow {
  padding: 5px 0;
}

.CheckboxReadonly {
  opacity: 1 !important;
  filter: none !important;

  :global(.k-checkbox-label) {
    opacity: 1 !important;
    filter: none !important;
  }
}

.TextField {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.TextArea {
  width: 100%;

  textarea {
    resize: none !important;
  }
}

.TimePickerRow {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.TimePickerLabel {
  margin-bottom: 2px;
  height: 15px;
  display: block;
  width: 110px;
  flex: 0 0 auto;
}

.TimePickerDropDown {
  width: 90px;
  margin-left: 10px;
}

.TimePicker {
  width: 120px;
  margin-left: 10px;
}

.HeaderLinkBtn {
  color: #fff !important;
  margin: -5px 3px -5px 0;
}

.HeaderTitle {
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.WarningInfoCard {
  padding: 4px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ExclamationListItemIcon {
  color: $error !important;
  line-height: 1;
}

.AlertListItemIcon {
  color: $warning !important;
  line-height: 1;
}

.WarningsList {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0 0;
  max-height: 120px;
  flex: 0 0 auto;
}

.WarningInfoRow {
  display: flex;
  align-items: center;
}

.Row {
  display: flex;
  align-items: center;
}

.RowCell {
  flex: 1;
}

.HeadRow {
  margin-bottom: 4px;
  font-weight: 500;
}

.InvalidMessage {
  color: $error;
  display: flex;
  flex-direction: column;
  text-align: right;
  flex: 1;
}

.AddCombobox {
  width: auto;
  flex: 1;
}

.AddBtn {
  margin-bottom: 5px;
  margin-left: 4px;
}

.AddDocumentFiles {
  :global(.k-upload-pct) + :global(.k-button) {
    display: none;
  }
}

.MobileAddDocumentFiles {
  :global(.k-dropzone-hint) {
    display: none;
  }
}

.MobileComboboxPopup {
  :global(.k-list-item) {
    height: 40px;
  }
}