@import '../../../assets/styles/colors.scss';

.Header {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 8px;
  // font-weight: 700;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;

  &[data-level='1'] {
    background-color: $secondary;
  }

  &[data-level='2'] {
    background-color: $lightgrey;
  }
}

.MobileListItem {
  display: flex;
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
}

.MobileListItemSelected {
  background: rgba($fxPrimary, .25);
}

