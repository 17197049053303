@import '../../../assets/styles/colors.scss';

.TabsToolbar {
  border: none;
  background: #f7f7f7;
  flex: 0 0 auto;
  height: 40px;
  position: relative;
}

.TabsBtnsGroup {
  display: flex;
}

.TabBtn {
  position: relative;
  font-size: 12px;
  color: #3d3d3d;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 8px;

  &:hover {
    color: black;
  }
}

.ActiveTabBtn {
  color: black;
}

.ActiveTabIndicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  border-bottom: 4px solid;
  color: $fxPrimary;
}

.TabIndicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  border-bottom: 4px solid;
  color: #e7e7e7;
}