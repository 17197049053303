.Container {
  position: relative;
  height: 75px;
  width: 100%;
  flex: 1;
}

// timeLine
.TimeLine {
  display: flex;
  align-items: center;
  height: 25px;
  border: dotted 1px #eff9ff;
  background-color: #eff9ff;
  margin-right: 50px;
  position: absolute;
  top: 25px;
  right: 0;
  left: 0;
  overflow-x: clip;
}

.TimeLineType {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 45px;
  font-size: 10px;
  font-style: italic;
  color: grey;
}

.TimeLineSegment {
  position: absolute;
  bottom: 0;
  top: 0;
  cursor: pointer;
}

.TimeLineTE {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 4px;
  z-index: 0;
  box-shadow: inset 0 0 2px 0 #ffffff;
  transition: 0.3s;
}

.TimeLineNoWO {
  width: 100%;
  position: absolute;
  top: -3px;
  height: 2px;
  background: repeating-linear-gradient(to right, #6e6e6e, #969696 2px, #fff 2px, #ffffff 4px);
}

.TimeLineTEApproved {
  height: 16px;
}

.TimeLineTENotApproved {
  height: 10px;
}

.TimeLineNoGPS {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 4px dotted;
  color: #aeaeae;
  z-index: 1;
}

.TimeLineSegment:hover {
  .TimeLineTE {
    opacity: 0.8;
  }

  .TimeLineTEApproved {
    height: 22px;
  }

  .TimeLineTENotApproved {
    height: 14px;
  }
}

.TimeLineFlagTE {
  position: absolute;
  bottom: 0;
  width: 1px;
  z-index: 2;
}

.ClockInFlag {
  color: transparent;
  border: 10px solid;
  border-left-color: green;
  border-right-width: 0;
  position: absolute;
  bottom: 32px;
  left: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.ClockOutFlag {
  color: transparent;
  border: 10px solid;
  border-right-color: red;
  border-left-width: 0;
  position: absolute;
  bottom: 22px;
  right: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.ForceClockOutFlag {
  border-top-color: red;
  border-top-left-radius: 3px;
}

.ForceClockOutSymbol {
  color: #fff;
  position: absolute;
  top: -8px;
  right: -7px;
  z-index: 8;
  font-size: 8px;
  font-weight: 900;
  line-height: 1;
}

.WaivedLunchFlag {
  position: absolute;
  bottom: 25px;
  right: -5px;
  cursor: pointer;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background: #f7bb7d;

  &:hover {
    opacity: 0.5;
  }
}

.DispatchFlagTE {
  position: absolute;
  top: 100%;
  height: 13px;
  width: 1px;
  background: #000;
}

.DispatchFlag {
  position: absolute;
  top: 100%;
  left: 0;
  border: 4px solid;

  &:hover {
    opacity: 0.7;
  }
}

.FinishDispatchFlag {
  left: initial;
  right: 0;
}

.DispatchTooltipRow {
  display: flex;
  font-size: 11px;
  align-items: center;
  padding: 1px 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(#fff, 0.5);
  }
}

.DispatchTooltipRowName {
  flex: 1;
  padding-left: 4px;
}

.DispatchTooltipColName,
.DispatchTooltipValue {
  width: 60px;
  flex: 0 0 auto;
  text-align: center;
  border-right: 1px solid rgba(#fff, 0.5);
  height: 14px;
}