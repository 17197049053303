.calendar-select-filter-popup {
  .k-list-content {
    scrollbar-width: thin;
  }
}

.calendar-select-filter {
  width: 200px !important;

  .k-input-values {
    max-height: 50px;
    overflow: hidden auto;
    scrollbar-width: thin;
  }
}
