@import '../../assets/styles/colors.scss';

.Splitter {
  height: 100%;
}

.RightPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Toolbar {
  flex: 0 0 auto;
}

.MultiSelect {
  width: 350px !important;
  max-width: 100%;

  :global(.k-select) {
    display: none !important;
  }

  :global(.k-multiselect-wrap li.k-button) {
    pointer-events: none;
  }

  :global(.k-chip-actions) {
    display: none;
  }
}

.MultiSelectNoSelected {
  :global(.k-chip) {
    background: none;
    line-height: 1.2;
    border: none !important;
    pointer-events: none;
  }
}

.MultiSelectPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  z-index: 5;
  cursor: pointer;

  &:hover .MultiSelectPlaceholderBtn {
    background-color: #ebebeb;
  }

  &.MultiSelectPlaceholderSelected {
    z-index: 0;

    .MultiSelectPlaceholderBtn {
      color: $fxPrimary;
    }
  }
}

.MultiSelectPlaceholderBtn {
  border-color: rgba(0, 0, 0, 0.08);
  color: $text;
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px;
}

.CLMFilterRow {
  width: 100%;
  display: flex;
  margin-right: 0 !important;
}

.MultiSelectPopup {
  max-height: 300px;

  :global(.k-treeview-item) {
    white-space: initial !important;
  }
}

.PopupPreview {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.MobileAuthor {
  margin-top: auto;
  text-align: right;
}

.MobileListActionBar {
  display: flex;
  align-items: center;
  margin-top: 8px;

  :global(.k-button) {
    margin-left: 4px;
  }
}

.BottomToolbarBtns {
  :global(.k-button) {
    margin-right: 8px;
  }
}

.MobileSingleRowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MobileToolbar {
  :global(.k-button) {
    padding: 8px;
    font-size: 16px;
    font-size: 16px;
    height: 40px;
  }

  :global(.k-button-icon) {
    font-size: 20px;
  }

  :global(.k-icon-button) {
    width: 40px;
  }

  :global(.k-clear-value) {
    width: 40px;
    height: 40px;

    :global(.k-icon) {
      font-size: 20px;
    }
  }
}
