@import '../../assets/styles/colors.scss';

.Controls {
  display: flex;
  flex-direction: column;

  :global(.k-button) {
    margin-top: 11px;
    padding: 0;
    color: #000;
  }

  :global(.mdi) {
    font-size: 22px;
  }
}

.GroupBtns {
  display: flex;

  &.LocationsGroup :global(.k-button) {
    color: #00c4ae;
  }

  &.SitesGroup :global(.k-button) {
    color: #c400ab;
  }

  &.AllSitesGroup :global(.k-button) {
    color: #ff6e60;
  }

  :global(.k-button) {
    margin-right: 10px;
    border-radius: 4px;

    &:nth-child(2) {
      color: #000;
    }
  }
}

.OtherNodesNotification {
  width: 250px;
  margin-top: 8px;
  height: 25px;
  line-height: 1;
}

.NotActualBtn {
  border: #e84c3d 1px solid;
}

.InProcess {
  background-image: url(./loading.gif);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;

  :global(.mdi) {
    display: none;
  }
}

.SearchControl {
  display: flex;
  align-items: center;
  height: 29px;
}

.SearchControlToggle :global(.k-button) {
  padding: 0;
  color: #000;
  border-radius: 4px;
  background: #fff;
  width: 27px;
  flex: 0 0 auto;
}
