@import '../../assets/styles/colors.scss';

$green: #00b100;

.Container {
  svg text[fill='red'] {
    fill: $error;
  }

  svg text[fill='green'] {
    fill: $green;
  }
}

.Grid {
  width: 652px;
  margin-top: 16px;
  transition: 0.1s;
  margin-bottom: -5px;
  position: relative;
  z-index: 2;

  td:not(:nth-child(1)) {
    text-align: right;
  }
}

.BoldRow {
  background-color: #f6f6f6;

  td {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.RedCell {
  color: $error;
}

.GreenCell {
  color: $success;
  color: $green;
}

.ScaleBtns {
  position: absolute;
  top: 16px;
  right: 16px;
  line-height: 1;
}
