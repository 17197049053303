@import '../../../assets/styles/colors';

.OuterContainer {
  position: relative;
}

.InnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ListGrid {
  width: 100%;

  :global(.k-grid-header), :global(.k-table-thead) {
    display: none;
  }

  :global(.k-table-row) {
    font-size: 14px;
  }

  :global(.k-table-row td) {
    border-bottom: 1px solid #d6d5d5;
  }
}