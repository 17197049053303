@import '../../../assets/styles/colors.scss';

.MobilePopupOverlay {
  z-index: 1;
}

.MobilePopupBox {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9;
}

.MobilePopup {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 2;
}

.MobilePopupBtn {
  width: 100%;
  justify-content: flex-start;
  height: 65px;
  flex: 0 0 auto;
  margin: 4px 0;
  font-size: 16px;

  :global(.k-button-icon) {
    font-size: 20px;
    margin-right: 8px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
