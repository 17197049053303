.editedValueBox {
  flex: 1;
  height: 25px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f1f1f1;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: -4px;
}

.ellipsisValue {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.GeneralContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px;
  overflow: auto;
  box-sizing: border-box;
  gap: 12px;
}

.DatePicker {
  margin: 1px 0;
}

.CurrencyBox {
  display: flex;
  justify-content: space-between;
}

.NotShowBtn td:first-child a {
  display: none;
}

.CommentCell {
  display: flex;
  align-items: center;
  position: relative;
}

.TemplateMaterialRow {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
}

.IconActionBtn {
  background: red;
}

.GreenActionIcon {
  color: green;
}

.RedActionIcon {
  color: red;
}

.CommentEllipsis {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}