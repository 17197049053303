@import '../../../assets/styles/colors.scss';

.NotShowBtn td:first-child a {
  display: none;
}

.ResoursesList {
  margin: 0px -4px;
  padding: 4px;
  border: 1px solid #f3f3f3;
  list-style: circle;
  border-top-width: 2px;
  border-bottom-width: 2px;
  padding-left: 24px;
}

.ResoursesListRow {
  list-style-type: circle;
}

.NumberColumn {
  text-align: center !important;
}