@import "../../../assets/styles/colors.scss";

.Box {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  min-width: 30px;
  min-height: 15px;
  flex: 0 0 auto;
}

.switchLabel {
  cursor: pointer;
}

.switch .switchCheckbox {
  display: none;
}

.switch + .switchSlider {
  display: block;
  cursor: pointer;
}

.switchSlider {
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 15px;
  display: block;
  width: 100%;
  -webkit-margin-end: 0 !important;

  & > span {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0px 20px;
    padding-right: 25px;
  }
}

.switchSlider {
  &:after,
  &:before {
    content: "";
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &:after {
    top: 0;
    left: 0;
    right: 5px;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    z-index: 1;
  }

  &:before {
    top: 2px;
    width: 11px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    z-index: 3;
  }
}

.switch .switchCheckbox:checked + .switchSlider {
  &:after {
    background-color: $fxPrimary;
  }

  &:before {
    transform: translateX(10px);
  }

  & > span {
    color: #fff;
  }
}

.switch .switchCheckbox:focus + .switchSlider:after {
  box-shadow: 0 0 1px $fxPrimary;
}

.rtlSwitch {
  flex-direction: row-reverse;

  .switchSlider {
    &:after {
      left: 5px;
      right: 0px;
    }

    &:before {
      left: 7px;
    }
  }
}
