@import '../../assets/styles/colors.scss';

.BtnIcon {
  font-size: 14px;
  color: rgb(61, 61, 61)
}

.CopyBtn {
  margin: -4px 4px;

  :global(.k-icon) {
    font-size: 12px;
  }
}

.Header {
  font-weight: 500;
  margin-bottom: 4px;
  text-align: center;
  border-bottom: 1px solid;
}

.SubTitle {
  margin-bottom: 4px;
  padding-left: 4px;
  font-weight: 500;
}

.Row {
  display: flex;
  align-items: center;
  padding-left: 4px;
  line-height: 1.5;
}

.FullVersion {
  .Row:before {
    content: '- ';
  }
}

.Separator {
  margin: 4px;
  border-bottom: 1px dotted;
  /*opacity: 0.5;*/
}