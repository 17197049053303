.Splitter {
  height: 100%;
}

.RightPanel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Toolbar {
  flex: 0 0 auto;
}

.NoPhoto {
  width: 100px;
  display: block;
  margin: auto;
}
