.ag-tool-panel-wrapper .ag-react-container {
  width: 100%;
}

.ag-status-bar {
  padding: 0;
}

.ag-set-floating-filter-input [disabled] {
  visibility: hidden;
}


.ag-sticky-bottom, .ag-row.ag-row-footer {
  font-weight: 600;

  .ag-cell {
    background: #dae4eb;
  }
}

.ag-header-cell-text {
  line-height: 1;
}

.ag-cell-value, .ag-group-value {
  line-height: 1.2;
  display: flex;
  align-items: center;
}

.ag-group-value {
  flex: 1;
}

.ag-cell-wrapper {
  flex: 1;
}

.ag-cell-wrapper.ag-row-group {
  align-items: center;
}

.comment-cell {
  align-items: flex-start;
  padding: 8px 0 4px;
  line-height: 1;
}

.ag-filter-apply-panel .ag-filter-apply-panel-button {
  padding: 4px 8px;
  margin-left: 4px;
}

:where(.ag-ltr) .ag-row:not(.ag-row-level-0) .ag-pivot-leaf-group {
  margin-left: 0;
}

.ag-status-bar {
  order: -1;
  border-bottom: 1px solid #d3caca;
  background: #fafafb;
}