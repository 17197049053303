@import '../../../assets/styles/colors.scss';

.gridcontshortc {
  :global(.k-table-row):first-child {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.filterRow {

}

.listContainer {
  flex: 1;
}

.ShortCutItem {
  cursor: pointer;
  padding: 4px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &.ShortCutItemOdd {
    background-color: rgba(0, 0, 0, 0.02)
  }

  &:hover {
    background-color: #f3f3f3;
  }

  &:focus, &.ShortCutItemSelected {
    background-color: #c8e5fc;
  }

  &:focus:hover, &.ShortCutItemSelected:hover {
    background-color: #bdd8ee;
  }
}