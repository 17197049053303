.ImgPreviewBox {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.ImgPreview {
  max-width: 100%;
  flex: 0 0 auto;
  max-height: 100%;
}

.NoDocument {
  padding: 5px;
}

.ImgLoader {
  position: absolute;
  display: flex;
  z-index: 1001;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.DocumentContainer {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 5;

  :global(.react-viewer-transition) {
    transition: none !important;
  }
}

.PrintBtn {
  position: absolute;
  z-index: 99999999;
  right: 6px;
  bottom: 6px;
  background: rgba(0, 0, 0, 0.5) !important;
  color: #fff;
  width: 28px !important;
  height: 28px !important;
  text-align: center;
  padding-left: 5px !important;
}
