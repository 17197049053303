@import '../../../assets/styles/colors.scss';

.ProgressBar {
  width: 100%;
  height: 25px;
  background: transparent;
  border-radius: 0;

  :global(.k-progress-status) {
    width: 100%;
  }

  :global(.k-selected) {
    color: #656565;
    background-color: rgba($success, 0.25);
    height: 25px !important;
  }
}

.ProgressBarOver {
  :global(.k-selected) {
    background-color: rgba($error, 0.25);
  }
}

.MarkedColumn {
  background-color: rgba($fxPrimary, 0.25) !important;
}
