@import '../../../../assets/styles/colors';

.ListBox {
  height: 100%;
  overflow: auto;
  padding: 28px 0;
}

.EditRow {
  clear: both;
  min-height: 40px;
  position: relative;

  &:hover {
    .TEEditNewTE {
      opacity: 1;
    }

    .DeleteBtn {
      opacity: 1;
    }
  }
}

.AdjustmentsCardComment {
  clear: both;
  width: 100%;
  height: 70px;
  margin-top: 8px;
  flex: 0 0 auto;
}

.TEEditNewTE {
  position: absolute;
  left: 148px;
  z-index: 3;
  top: -8px;
  padding: 0 !important;
  height: 16px !important;
  width: 16px !important;
  transition: none !important;
  opacity: 0;
  color: $fxPrimary !important;

  :global(.k-icon) {
    font-size: 12px;
  }
}

.TEEditNewTEFinish {
  top: 32px;
}

.AdjustmentEdit {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 90px;
  margin-left: 26px;
  height: 40px;
  box-sizing: border-box;
  border-top: 1px solid #f8f8f8;

  &:hover {
    background: #f2f2f2;
  }
}

.EditRowBorderBottom {
  border-bottom: 1px solid #f8f8f8;
}

.TE {
  display: flex;
  position: relative;
  height: 40px;
  box-sizing: border-box;
  font-size: 9px;
  align-items: center;
  z-index: 2;
  min-width: 220px;
  flex: 1;
}

.TEContent {
  height: 100%;
  position: relative;
  flex: 1;
  z-index: 1;
  padding-left: 24px;
}

.TEText {
  word-break: break-all;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}

.LunchDuration {
  width: 75px;
  margin-right: 4px;
  padding-left: 7px;
  box-sizing: border-box;
}

.TEDetailsContent {
  text-align: left;
  max-width: 300px;
}

.TEContentBox {
  width: 100%
}

.TEDetailsRow {
  display: flex;
  gap: 4px
}

.TECostType {
  width: 12px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  color: #e0e0e0;
  cursor: pointer;
  transition: 0.2s;
  z-index: 2;

  &:hover {
    opacity: 0.7;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: 12px solid;
    display: block;
  }

  &.thin {
    background: none !important;

    &:before {
      border-left-width: 6px;
      right: 3px;
    }
  }
}

.TE {
  &:global(.stop) {
    .TECostType:after {
      border: 6px solid;
      border-radius: 50%;
      box-shadow: #fff 0 0 0 2px;
      left: -3px;
      margin-top: -6px;
    }

    .TECostType.thin:after {
      left: 0.5px;
      border-width: 4px;
      margin-top: -4px;
    }
  }
}

.TETimeEdit {
  width: 83px;
  height: 26px;
  margin: -13px 0;
  float: left;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  margin-left: 26px;

  input {
    font-size: 9.6px;
    outline: none;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.DeleteBtn {
  padding: 0 !important;
  height: 16px !important;
  width: 16px !important;
  transition: none !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  margin: auto;
  z-index: 2;
  opacity: 0;
  color: $error !important;

  :global(.k-icon) {
    font-size: 12px;
  }
}

.TEEditSplit {
  position: absolute;
  left: -21px;
  top: 5px;
  padding: 0 !important;
  height: 16px !important;
  width: 16px !important;
  opacity: 0.2;

  :global(.mdi) {
    font-size: 12px;
  }

  &:hover {
    opacity: 1;
  }
}

.TEEditDuration {
  margin-right: 4px;
  width: 75px;
  flex: 0 0 auto;
}

.TEEditWOAllocationBox {
  cursor: pointer;
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.CostTypeMenuItem {
  flex-direction: row;

  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 21px;
    border-left: 5px solid;
    position: relative;
    top: 2px;
    margin-left: 2px;
    margin-right: -2px;
  }

  :global(.k-menu-link-text) {
    color: $text;
  }

  &:hover {
    background: #fff !important;

    :global(.k-menu-link-text) {
      color: #000;
    }
  }
}

.SelectedMenuItem {
  :global(.k-menu-link-text) {
    color: $fxPrimary !important;
  }
}

.AdjustmentCardToolbar {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  :global(.k-button) {
    margin-left: 8px;
  }
}

.DurationItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  margin-right: 8px;

  & > span {
    line-height: 1;
    display: flex;
    align-items: flex-end;
  }

  :global(.k-chip) {
    font-size: inherit;
    min-width: 44px;
    flex: 0 0 auto;
    text-align: center;
    margin-top: 4px;
    cursor: text;
    box-shadow: none !important;
    user-select: initial;
  }
}

.KendoTimePicker {
  :global(.k-picker-wrap) {
    flex-direction: row-reverse;
  }

  :global(.k-button) {
    height: 24px;
  }

  :global(.k-select) {
    opacity: 0.2;
    border-color: transparent;
    background-color: transparent;
    background-image: none;
  }

  &:hover {
    :global(.k-select) {
      opacity: 1;
      border-color: rgba(0, 0, 0, 0.08);
      color: #424242;
      background-color: #f5f5f5;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
    }
  }
}

.AdjustmentDropDown {
  width: 40px;
  margin-right: 4px;
  background: #fff;
  height: 26px;

  button {
    display: none;
  }
}

.AdjustmentDropDownTree {
  width: 70px;

}

.AdjustmentDropDownTreePopup {
  :global(.k-treeview-item) {
    padding-left: 0;
  }
}

.AllocationDropDown {
  flex: 1;
  margin-right: 4px;
  font-size: 9px;

  button {
    height: 24px;
  }
}

:global(.k-list-content::-webkit-scrollbar) {
  width: 4px;
  background-color: #F5F5F5;
}

:global(.k-list-content::-webkit-scrollbar-thumb) {
  background-color: $grey;
}

:global(.k-list-content) {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.StaticWORow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 2px;

  &:hover {
    background: #f1f1f1;
  }
}