@import '../../assets/styles/colors.scss';

.ButtonGroup {
  box-sizing: border-box;
  flex-wrap: wrap;
  align-self: start;

  :global(.k-button) {
    width: 90px !important;
    flex: 0 0 auto;
    margin: 0 !important;
  }
}

.CrewLeadMultiselect {
  min-width: 250px;
}

.CrewLeadMultiselectList {
  :global(.k-list-item ) {
    box-shadow: none !important;
    position: relative !important;
  }

  :global(.k-selected) {
    color: #424242;
    background-color: #fff;

    &:hover {
      color: #424242;
      background-color: #f0f0f0;
    }
  }
}

.CrewLeadChips {
  border-color: inherit;

  :global(.k-chip-content),
  :global(.k-chip-icon) {
    color: #424242;
  }

  &:before {
    content: '';
    border: 2px solid;
    border-radius: 50%;
    margin-right: -4px;
    opacity: 0;
  }

  &:hover {
    background: #fff;
  }

  &:global(.highlighted):before {
    opacity: 1;
  }
}
