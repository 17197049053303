@import '../../../assets/styles/colors.scss';

.Box {
  display: flex;
  height: 100%;
}

.SideBarBox {
  margin: -8px -8px -8px 12px;
}

.InstractionsBox {
  padding: 8px;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  font-size: 14px;
  white-space: pre-wrap;
}

.InstractionsTitle {
  font-weight: 600;
  margin-bottom: 8px;
}

.BPNameInput {
  flex: 1 !important;
}

.CustomNameCheckBox {
  width: 110px;
  padding-left: 8px;
  margin-bottom: 5px;
  text-align: right;
  flex: 0 0 auto;
}

.Left {
  width: 360px;
  padding-right: 16px;
  box-sizing: border-box;
  flex: 0 0 auto;
}

.Right {
  flex: 1;
}

.LinkBox {
  padding: 4px 8px;
  margin-bottom: 5px;
}

.RoleColumn {
  flex: 1;
  padding-right: 8px;
  box-sizing: border-box;
}

.RoleDeleteColumn {
  width: 32px;
  text-align: right;
}

.AddRoleBtn {
  width: 100%;
  padding: 0 !important;
  height: auto;
  border: none;
}

.OpenSiteCardBtn {
  position: absolute;
  z-index: 1;
  right: 45px;
  opacity: 0.5;

  &:hover {
    opacity: 1;

    &:before {
      opacity: 0;
    }
  }
}
