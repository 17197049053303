@import '../../assets/styles/colors.scss';

.Container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.Toolbar {
  flex: 0 0 auto;
}

.Content {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
  position: relative;
}

.CurrentWeek {
  margin: 0 16px;
  margin: 0;
  font-weight: 700;
}

.Legend {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;

  :global(.k-icon) {
    margin-left: 8px;
    margin-right: 4px;
  }
}

.BPOwnerFilter,
.BPNameFilter {
  width: 200px !important;
  position: relative;
  z-index: 2;
}

.Card {
  width: 300px;
  margin: 8px;
  border-radius: 0;
  box-shadow: none;
}

.CardHeader {
  padding: 4px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CardTitle {
  font-size: 12px;
}

.CardBody {
  height: 200px;
  padding: 4px;
  box-shadow: none;
  border-radius: 0;
}

.CardActions {
  display: flex;
  justify-content: center;
}

.DetailsRow {
  display: flex;
  align-items: center;
  padding: 4px;

  &:nth-child(odd) {
    background: $lightgrey;
  }
}

.DetailsIcon {
  width: 20px;
  flex: 0 0 auto;
}

.DetailsName {
  flex: 1;
}

.DetailsValue {
  width: 60px;
  flex: 0 0 auto;
  text-align: right;
}
