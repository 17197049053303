@import '../../assets/styles/colors.scss';

:global(.k-grid col:not([width])) {
  min-width: 50px;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex: 1 1;
}

.Container,
:global(.fixed-height-container-for-grid) {
  .Container {
    position: relative;
  }
}

:global(.k-grid .k-grid-md .k-table-th > .k-cell-inner), :global(.k-grid-md .k-table-th > .k-cell-inner) {
  margin-block: 0;
  margin-inline: 0;
  margin: -8px calc(-1.42857em - -10px) -8px -12px;
}

.Grid {
  :global(th.k-header),
  :global(.k-column-title) {
    white-space: normal;
    //padding-top: 0 !important;
    //padding-bottom: 0 !important;
    word-break: normal;
    vertical-align: middle;
  }

  :global(.k-grid-header .k-table-th) {
    vertical-align: middle;
  }

  &.GridCollapse {
    :global(.k-grid-container tr:not(.k-selected)),
    :global(.k-grid-header tr.k-filter-row),
    :global(.k-grid-footer) {
      display: none;
    }
  }

  :global(.k-numeric-wrap .k-select) {
    display: none;
  }

  .HighlightColumn {
    background: rgba($fxPrimary, 0.25);
  }
}

.CurrencyBox {
  position: relative;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: right;
}

.Currency {
  position: absolute;
  left: 0;
  top: 0;
}

.AlignTop {
  vertical-align: top;
}

:global(.k-button).BlueFilter {
  color: $fxPrimary;
}

.FilteredColumnTH {
  :global(.k-column-title):after {
    content: "\f14b";
    text-indent: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    display: inline-block;
    color: $fxPrimary;
    vertical-align: top;
    position: relative;
    top: -3px;
    font-family: "foundation-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    text-decoration: inherit;
  }
}

.DisabledColumnMenuBtn {
  pointer-events: none;
  z-index: -1;
  cursor: default !important;
  color: #eee;
}

:global(.k-columnmenu-item) {
  position: relative;

  &:after {
    content: '\e006';
    width: 1em;
    height: 1em;
    outline: 0;
    font-size: 16px;
    font-family: 'WebComponentsIcons';
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
    text-decoration: none;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    vertical-align: middle;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 3px;
    top: 3px;
  }
}

:global(.valuesExpanded) :global(.k-columnmenu-item),
:global(.columnsExpanded) :global(.k-columnmenu-item),
:global(.filtersExpanded) :global(.k-columnmenu-item) {
  &:after {
    content: '\e004';
  }
}

:global(.columnMenuExpandPanel.filtered .k-i-filter) {
  color: $fxPrimary;
}

.TimeRangeSlider {
  width: 250px;
  margin-right: 8px;
  margin-left: 8px;
  position: relative;
  padding-top: 4px;
  margin-top: 8px;

  :global(.rc-slider) {
    z-index: 1 !important;
  }
}

:global(.rc-slider-track) {
  background: $fxPrimary !important;
}

:global(.rc-slider-handle),
:global(.rc-slider-handle-dragging) {
  background-color: $fxPrimary !important;
  opacity: 1 !important;
  border-color: $fxPrimary !important;
  box-shadow: none !important;

  &:focus-visible,
  &:hover,
  &:active {
    border-color: $fxPrimary !important;
    box-shadow: none !important;
  }
}

.TimeRangeSliderValue {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.8rem;
}

.ColumnmenuFilter {
  :global(.k-textbox),
  :global(.k-dropdownlist),
  :global(.k-datepicker),
  :global(.k-numerictextbox) {
    margin-bottom: 4px;
  }

  :global(.k-textbox) {
    width: 100%;
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-sizing: border-box;

    border-width: 1px;
    border-style: solid;
    outline: 0;

    font-family: inherit;
    font-size: 12px;
    line-height: 1.42857;
    font-weight: normal;
    text-align: start;

    box-shadow: none;

    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 8px;

    &:hover {
      border-color: rgba(0, 0, 0, 0.16);
    }

    &:focus {
      border-color: rgba(0, 0, 0, 0.16);
    }
  }

  :global(.k-actions) {
    justify-content: flex-end;
  }

  :global(.k-actions .k-button) {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    grid-gap: 4px;
    gap: 4px;

    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .08);
    outline: none;

    padding: 4px 8px;
    box-sizing: border-box;
    position: relative;
    width: auto !important;

    font-size: 12px;
    line-height: 1.42857;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    vertical-align: middle;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    color: #424242;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .02));
  }

  :global(.k-actions .k-primary.k-button) {
    border-color: $fxPrimary;
    color: #fff;
    background-color: $fxPrimary;
  }
}

.GroupStatusDashboardGrid {
  td:not(:first-child) {
    text-align: center;
  }

  th {
    word-break: normal;
  }
}

.HiddenDateInput {
  width: 0 !important;
  overflow: hidden !important;
}

.QuickFilterDatepicker {
  width: 28px;
}

.BlueResetBtn {
  color: $fxPrimary;
}

.ToolbarContent {
  flex: 1;
  flex-wrap: wrap;
  margin-right: 0 !important;
  align-self: flex-start;

  :global(.k-checkbox-label) {
    margin-right: 8px;
  }
}

.ToolbarRow {
  display: flex;
  width: 100%;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  :global(.k-widget, .k-combobox),
  :global(.k-dropdownlist),
  :global(.k-datepicker),
  :global(.k-multiselect),
  :global(.k-combobox),
  :global(.k-daterangepicker),
  :global([dir]) {
    margin-right: 8px;
  }

  .ChipCounter {
    width: 55px;
    text-align: center;
    font-size: 12px;
    margin-right: 8px;
  }
}

.RightBtns {
  display: flex;
  flex-direction: row;

  :global(.k-button):not(:first-child) {
    margin-left: 8px;
  }
}

.ModeSwitchContainer {
  display: flex;
  align-self: flex-start;
  padding: 6px 0;
}

.THWithArrow {
  color: #fff;
  font-weight: 600 !important;

  :global(.k-cell-inner) {
    position: relative;
    z-index: 1;
    padding-left: 6px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    border: 24px solid;
    top: 0;
    border-color: transparent;
    border-left-color: #fff;
    border-left-width: 6px;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background: #958198;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    border: 24px solid;
    right: -24px;
    top: 0;
    border-color: #fff;
    border-left-color: transparent;
    border-left-width: 10px;
  }

  &.DarkBlue:before {
    background: #333b48;
  }

  &.GreyPink:before {
    background: #948197;
  }

  &.Blue:before {
    background: #48a4d7;
  }

  &.GreenBlue:before {
    background: #47abb7;
  }

  &.Orange:before {
    background: #f4a24e;
  }

  &.Red:before {
    background: #f16f79;
  }
}

.THWithArrowThin {
  &:before {
    border-width: 16px;
    border-left-width: 4px;
  }

  &:after {
    border-width: 16px;
    right: -15px;
    border-left-width: 10px;
  }
}

.DateRangeFilter {
  width: 255px;
  justify-content: space-between;

  :global(.k-dateinput) {
    width: 124px;
    border: none;
  }

  :global(.k-floating-label-container) {
    padding-top: 0;
  }

  :global(.k-floating-label),
  :global(.k-icon-button) {
    display: none;
  }
}