@import '../../../assets/styles/colors.scss';

.TCContainer {
  height: 100%;
  min-width: 620px;
  display: flex;
  flex-direction: column;
}

.TCInfoBox {
  padding: 4px 0;
  flex: 0 0 auto;
}

.TCRow {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
}

.TCCell {
  &:nth-child(1) {
    width: 110px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.TCInfoName {
  padding-right: 4px;
}

.TCInfoTime {
  width: 52px;
  text-align: right;
}

.TCInfoCell {
  padding: 2px 4px;
  display: flex;
  align-items: center;
}

.TCClockInOut {
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 35px;

  .TCInfoCell {
    justify-content: flex-end;
  }
}

.TooltipRow {
  display: flex;
  font-size: 10px;
}

.TooltipRow:not(:last-child) {
  border-bottom: 1px solid;
}

.TooltipRowName,
.TooltipColName,
.TooltipValue {
  padding: 2px 0;
}

.TooltipRowName {
  width: 45px;
  flex: 0 0 auto;
}

.TooltipColName,
.TooltipValue {
  width: 90px;
  flex: 0 0 auto;
  text-align: center;
  border-left: 1px solid;
}

.TCGrid {
  margin-bottom: 4px;

  th,
  &:not(.TCGridWithoutTotalRow) tr:last-child {
    font-weight: 700;
  }
}

.TCGridHidden {
  tbody {
    display: none;
  }

  th:not(:first-child) {
    opacity: 0;
  }

  th:first-child {
    border-bottom: 0;
  }
}

.TCGridToggle {
  padding: 0 !important;
  height: 14px !important;
  width: auto !important;
}

.TCGridToggleCell {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.PrevailingWage {
  color: $error;
}

.TCWidgets {
  display: flex;
  justify-content: center;

  :global(.k-toolbar) {
    margin-bottom: 12px;
    gap: 2px;

    &::before {
      margin-inline-start: -2px;
    }
  }
}

.TCMileage {
  width: 130px;
  flex: 0 0 auto;
}

.TCTimeLine {
  flex: 1;
}

.TCLockedWageSummury {
  color: $error;
}

.AdjustmentsApplied {
  color: $success;
}

.AdjustmentsRejected {
  color: $error;
}

.ResetBtn {
  :global(.mdi) {
    font-size: 19px;
  }
}

.InfoBtn {
  :global(.mdi) {
    font-size: 18px;
    top: 1px;
    position: relative;
  }
}

.WaivedLunch {
  background: #f7bb7d;
  margin-right: 5px;
  color: #fff;
  padding: 0 5px;
  border-radius: 15px;
}

.TCDetailsGridContainer {
  height: auto;
}

.TCDetailsScrollGridContainer {
  height: 350px;
}

.TCDetailsGrid {
  :global(.k-filter-row) {
    display: none;
  }
}

.MultipleWOBtn {
  cursor: pointer;
  font-weight: bold;
}

.WORow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 2px;

  &:hover {
    background: #f1f1f1;
  }
}