.Container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ListContainer {
  display: flex;
  flex-direction: column;
}

.SelectedContainer {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}
