$sideBarBtnWidth: 16px;

.ChildrenBox {
  height: 100%;
  display: none;
}

.Sidebar {
  z-index: 5;
  background: #fff;
  padding-right: $sideBarBtnWidth;
  box-sizing: border-box;

  &.Opened {
    .ChildrenBox {
      display: block;
    }

    .Btn {
      background: #f4f4f4;
      opacity: 1;
    }

    .BtnText {
      color: #a29e9e;
    }
  }
}

.Btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $sideBarBtnWidth;
  text-align: center;
  cursor: pointer;
  background: #87cefa;
  opacity: 0.8;
  box-sizing: border-box;

  &:hover {
    opacity: 1;
  }

  .k-icon {
    font-size: 20px;
    float: right;
  }
}

.BtnText {
  font-size: 11px;
  width: $sideBarBtnWidth;
  line-height: $sideBarBtnWidth;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: inherit;
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 0 0 5px 0;
}

.BtnClickable {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.BtnAdditionalBtns {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 2;
}

.Box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding-left: $sideBarBtnWidth;

  &.RightSide {
    padding-right: $sideBarBtnWidth;
    padding-left: 0;
  }

  &.OverlaySidebar {
    .Sidebar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .Overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
    }
  }

  &.PushSidebar {
    display: flex;
    padding: 0;

    .Sidebar {
      flex: 0 0 auto;
      position: relative;
      height: 100%;

      &.Opened {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.RightSide {
  .Sidebar {
    padding-left: $sideBarBtnWidth;
    padding-right: 0;
    z-index: 5;
  }

  .Btn {
    right: auto;
    left: 0;

    .k-icon {
      float: left;
    }
  }

  .BtnText {
    writing-mode: vertical-lr;
  }
}

