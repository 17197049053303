@import '../../../assets/styles/colors.scss';

.MultiSelect {
  width: 350px;

  :global(.k-select),
  :global(.k-chip-remove-action) {
    display: none !important;
  }

  :global(.k-multiselect-wrap li.k-button) {
    pointer-events: none;
  }
}

.MultiSelectNoSelected {
  :global(.k-clear-value),
  :global(.k-chip-remove-action) {
    display: none;
  }

  :global(.k-chip) {
    text-align: left;
    background: none !important;
    justify-content: flex-start;
    border: none !important;
    pointer-events: none;
  }
}

.MultiSelectPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  z-index: 5;
  cursor: pointer;

  &:hover .MultiSelectPlaceholderBtn {
    background-color: #ebebeb;
  }

  &.MultiSelectPlaceholderSelected {
    z-index: 0;

    .MultiSelectPlaceholderBtn {
      color: $fxPrimary;
    }
  }
}

.MultiSelectPlaceholderBtn {
  border-color: rgba(0, 0, 0, 0.08);
  color: $text;
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px;
}

.MultiSelectPopup {
  max-height: 90vh;
  overflow: auto;

  :global(.k-treeview-item) {
    padding-left: 0;
  }
}

.ProcessingButtonBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 27.4px;
}

.ProcessingButtonLoader {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.DurationInputBox {
  width: 100%;
  height: 26px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: inherit;
  font-size: 9px;
  -webkit-appearance: none;
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px;
  box-sizing: border-box;

  &:hover {
    .DurationInputButtons {
      opacity: 1;

      .DurationInputClear {
        color: $error;
      }

      .DurationInputReset {
        color: $fxPrimary;
      }
    }
  }
}

.DurationInputBgInput {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: none;
  box-sizing: border-box;
  z-index: 1;
}

.DurationInputHours,
.DurationInputMinutes {
  border: none;
  width: 15px;
  margin: 0;
  padding: 0;
  line-height: 18px;
  text-align: center;
  position: relative;
  font-size: 9px;
  outline: none;
  top: 1px;
  height: 18px;
  z-index: 2;
}

.DurationInputSeparator {
  position: relative;
  top: -1px;
  z-index: 2;
}

.DurationInputButtons {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  z-index: 2;
  opacity: 0.2;

  button {
    height: 100% !important;
    padding: 2px !important;
    width: 18px !important;
    transition: none !important;
  }

  :global(.k-icon) {
    font-size: 10px;
  }

  .DurationInputClear {
    :global(.k-icon) {
      font-size: 12px;
      top: 0.2px;
    }
  }
}

.DateRangeSlider {
  width: 220px;
  box-sizing: border-box;
  font-size: 10px;
}

.DateRangeSliderBox {
  width: 200px;
  margin: 0 auto;
}

.DateRangeSliderExtremes {
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
  margin: -10px 0 -5px;
}

.DateRangeSliderValue {
  text-align: center;
  font-weight: 600;
  line-height: 1;
  height: 10px;
}

.InvalidMessage {
  color: $error;
  display: flex;
  flex-direction: column;
  text-align: right;
  flex: 1;
}

.MobileForm {
  :global(.k-button) {
    padding: 8px;
    font-size: 16px;
    font-size: 16px;
    height: 40px;
  }

  :global(.k-button-icon) {
    font-size: 20px;
  }

  :global(.k-icon-button) {
    width: 40px;
  }

  :global(.k-clear-value) {
    width: 40px;
    height: 40px;

    :global(.k-icon) {
      font-size: 20px;
    }
  }

  :global(.k-grid-edit-row .k-textbox) {
    height: 30px;
  }

  .InvalidMessage {
    font-size: 16px;
  }
}
